import './index.less';

import Toast from 'antd-mobile/es/components/toast';
import { ToastShowProps } from 'antd-mobile/es/components/toast/methods';

const { show } = Toast;

Toast.show = (args: ToastShowProps | string) => {
  Toast.clear();
  return show(args);
};

export { Toast };
export default Toast;
