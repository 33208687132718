import 'antd-mobile/es/global';
import './plugins/index';

import requestConfig from '@falla/request-service/src/request.config';
import { getBrowserInfos } from '@falla/utils/src/common-util';
import { IAppConfig, runApp } from 'ice';

document.documentElement.setAttribute('mobile', String(getBrowserInfos().isMobile));

const appConfig: IAppConfig = {
  router: {
    type: 'hash',
  },
  request: requestConfig,
};

runApp(appConfig);
