export const definedMappingEnum = <T extends EnumMapping>(mapping: T) => {
  const result: Record<T[keyof T]['enumKey'] | T[keyof T]['value'], T[keyof T]['value']> = {} as any;
  for (const key in mapping) {
    // @ts-ignore
    if (mapping[key].enumKey) result[mapping[key].enumKey] = mapping[key].value;
    // @ts-ignore
    if (mapping[key].value) result[mapping[key].value] = mapping[key].value;
  }
  return result;
};

export const definedMapping = <T extends EnumMapping>(mapping: T | (() => T)) => {
  if (typeof mapping === 'function') {
    return mapping();
  } else {
    return Object.freeze(mapping);
  }
};

export interface EnumMappingItem {
  label: string;
  value: number | string;
  // 枚举类型
  enumKey?: string | number;
  color?: string;
  disabled?: boolean;
  [key: string]: any;
}

export interface EnumMapping {
  [enumValue: string]: EnumMappingItem;
}

export const ActivityTypeMapping = definedMapping(() => {
  return {
    // 1: { value: 1, label: '国家日模板', enumKey: 'nationalDay' },
    2: { value: 2, label: '个人送礼榜单', enumKey: 'rankUserSendGift' },
    3: { value: 3, label: '个人收礼榜单', enumKey: 'rankUserReceiveGift' },
    4: { value: 4, label: '个人充值榜单', enumKey: 'rankUserRecharge' },
    5: { value: 5, label: '普通房间榜单', enumKey: 'rankOrdinaryRoom' },
    6: { value: 6, label: '充值任务阶梯', enumKey: 'taskRechargeStep' },
    7: { value: 7, label: '送礼任务阶梯', enumKey: 'taskSendGiftStep' },
    8: { value: 8, label: '收礼任务阶梯', enumKey: 'taskReceiveGiftStep' },
    9: { value: 9, label: '任务积分领奖', enumKey: 'taskRewardPoints' },
  } as const;
});

export const ActivityTypeEnum = definedMappingEnum(ActivityTypeMapping);

export const RankActivityTypes: number[] = [
  ActivityTypeEnum.rankUserSendGift,
  ActivityTypeEnum.rankUserReceiveGift,
  ActivityTypeEnum.rankUserRecharge,
  ActivityTypeEnum.rankOrdinaryRoom,
];
export const TaskActivityTypes: number[] = [
  ActivityTypeEnum.taskRechargeStep,
  ActivityTypeEnum.taskSendGiftStep,
  ActivityTypeEnum.taskReceiveGiftStep,
  ActivityTypeEnum.taskRewardPoints,
];
