import { getCssBackgroundImage, px2rem } from '@falla/utils/src/format-util';
import classNames from 'classnames';
import * as React from 'react';

import styles from './index.module.less';

export type ActivityButtonProps = PropsWithBaseProps<{
  backgroundImage?: string;
  width?: number | string;
  height?: number | string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  supportDisabledClick?: boolean;
  onDisabledClick?: (event: React.MouseEvent) => void;
}>;

const ActivityButton: React.FC<ActivityButtonProps> = (props) => {
  const { backgroundImage, width, height, supportDisabledClick } = props;
  return (
    <button
      className={classNames(styles.activityButton, props.className, { [styles.isDisabled]: props.disabled })}
      disabled={!supportDisabledClick && props.disabled}
      style={{
        ...(props.style || {}),
        backgroundImage: backgroundImage ? getCssBackgroundImage(backgroundImage) : undefined,
        backgroundSize: backgroundImage ? '100% 100%' : undefined,
        width: px2rem(width),
        height: px2rem(height),
      }}
      onClick={(e) => {
        if (props.disabled) return props.onDisabledClick && props.onDisabledClick(e);
        props.onClick && props.onClick(e);
      }}
    >
      {props.children}
    </button>
  );
};

export default ActivityButton;
