// 可考虑走公共cdn引入，节省打包体积
// https://www.gstatic.com/firebasejs/8.10.0/firebase-analytics.js
// https://www.gstatic.com/firebasejs/8.10.0/firebase-app.js
import appBridge from '@falla/app-bridge/src';
import { AnalyticsCallOptions, CustomEventName, getAnalytics, logEvent as _logEvent } from 'firebase/analytics';
import { initializeApp, setLogLevel } from 'firebase/app';

interface Options {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

let projectName = '';

const init = (packageName: string, options: Options) => {
  projectName = packageName;
  const firebaseApp = initializeApp(options);
  setLogLevel('silent');
  window.firebaseApp = firebaseApp;
  window.gaAnalytics = getAnalytics(firebaseApp);
  window.logEvent = logEvent;
  window.customEvent = customEvent;
};

function checkGaAnalyticsInit() {
  if (!window.gaAnalytics) throw Error('请先初始化 firebase analytics');
}

export interface EventParams {
  eventName: CustomEventName<string>;
  eventParams?: { [key: string]: any };
  options?: AnalyticsCallOptions;
}

const logEvent = (params: EventParams) => {
  checkGaAnalyticsInit();
  const eventParams = params.eventParams || {};
  eventParams.project_name = projectName;
  eventParams.region = appBridge.getAppUserInfo().region || 'unknown';
  _logEvent(window.gaAnalytics, params.eventName, params.eventParams, params.options);
};

const customEvent = (params: EventParams) => {
  const customParams = { ...params };
  customParams.eventName = `falla_${projectName}_${params.eventName}`;
  logEvent(customParams);
};

export const firebaseAnalytics = { init, customEvent, logEvent };
export default firebaseAnalytics;
