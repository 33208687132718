import Spacing from '@components/activity/src/components/Spacing';
import appBridge from '@falla/app-bridge/src';
import { AppTag } from '@falla/app-bridge/src/types';
import { px2rem } from '@falla/utils/src/format-util';
import { SpinLoading } from 'antd-mobile';
import classNames from 'classnames';
import React, { CSSProperties } from 'react';

import styles from './index.module.less';

export enum ButtonTypes {
  default = 'default',
  primary = 'primary',
  primaryGradient = 'primaryGradient',
  custom = 'custom',
}

export type AppButtonProps = {
  type?: ButtonTypes;
  disabled?: boolean;
  plain?: boolean;
  style?: CSSProperties;
  link?: boolean;
  loading?: boolean;
  loadingSize?: number | string;
  onClick?: () => void;
  appTag?: AppTag;
} & BaseProps;

const isYiGoApp = appBridge.isYigoApp();
const isArhboApp = appBridge.isArhboApp();

const AppButton = (props: AppButtonProps) => {
  return (
    <button
      className={classNames(props.className, styles.button, {
        [styles.buttonPrimary]: props.type === ButtonTypes.primary,
        [styles.buttonDefault]: props.type === ButtonTypes.default,
        [styles.buttonPrimaryGradient]: props.type === ButtonTypes.primaryGradient,
        [styles.isPlain]: !!props.plain,
        [styles.isLink]: !!props.link,
        [styles.isYiGoApp]: isYiGoApp || props.appTag === AppTag.Yigo,
        [styles.isArhboApp]: isArhboApp || props.appTag === AppTag.Arhbo,
      })}
      style={props.style}
      disabled={props.disabled || props.loading}
      onClick={() => {
        if (props.disabled || props.loading) return;
        props.onClick && props.onClick();
      }}
    >
      {props.loading ? (
        <div className={styles.buttonLoadingWrap}>
          <SpinLoading color={'currentColor'} style={{ '--size': px2rem(props.loadingSize) }} />
          <Spacing width={20} />
          {props.children}
        </div>
      ) : (
        props.children
      )}
    </button>
  );
};

AppButton.defaultProps = {
  type: ButtonTypes.default,
};

export default AppButton;
