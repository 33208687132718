/*
 * @Description: 用户协议
 * @Author: liaoxingfeng@iyinguo.com
 */
import { useStarkTranslation } from '@falla/hooks/src';
import useDocumentTitle from '@falla/hooks/src/useDocumentTitle';
import { replaceFallaToYigo } from '@falla/utils/src/format-util';
import React from 'react';

import styles from './index.module.less';

interface IProps {}

const UserAgreement: React.FC<IProps> = () => {
  const { t } = useStarkTranslation();
  useDocumentTitle(t('userAgreement', ''), { restoreOnUnmount: true });

  return (
    <div className={styles.userAgreement}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{t('userAgreement')}</div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: replaceFallaToYigo(t('userAgreementContent')) }}
        />
      </div>
    </div>
  );
};

export default React.memo(UserAgreement);
